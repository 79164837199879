<nav id="sidebar">
    <ul class="list-unstyled components navbar-nav" id="accordionExample">

        <li class="header__mobile d-lg-none">
            <div class="header">
                <div class="d-flex justify-content-around text-white">
                    <font class="header_font text-white">Idioma:</font>
                    <a class="header__link" [ngClass]="{'active' : translate.currentLang === 'pt'}"
                       href="javascript:void('');"
                       (click)="switchLanguage('pt')">PT</a>
                    <span class="separator">|</span>
                    <a class="header__link" [ngClass]="{'active' : translate.currentLang === 'es'}"
                       href="javascript:void('');"
                       (click)="switchLanguage('es')">ES</a>
                </div>
                <div class="header__btn mobile_notification notification_trigger d-none">
                    <app-notification></app-notification>
                </div>
            </div>
        </li>

        <li class="nav-item  d-lg-none">
            <a href="javascript:void(0);" [routerLink]="['/']" class="nav-link text-white sidebar_top"
               style="height: initial;"
               (click)="actionCloseCollapse()">
                <span class="ico"><svg-icon src=""></svg-icon></span>
                <span class="text-center">
                  <!--<span class="lbl sidebar-title">
                      BIO
                  </span>-->
                  <span class="d-block d-lg-none header__user mb-0">
                    <span>{{'headerSalutation' | translate}}, <strong>{{user.name}}</strong></span>
                    <img src="{{!user?.photo ? 'assets/images/user.png' : user?.photo}}"
                         class="profile_photo"/>
                  </span>
                </span>
            </a>
        </li>

        <li class="nav-item" [ngClass]="{'active':routerNotParm === '/'}" *ngIf="home">
            <a href="javascript:void('Nota');" [routerLink]="['/']" class="nav-link align-items-center"
               style="gap: 20px"
               (click)="actionCloseCollapse()">
                <svg-icon src="assets/svg/home.svg"></svg-icon>
                <span class="lbl">Home</span>
            </a>
        </li>


        <li class="nav-item" [ngClass]="{'active':routerNotParm === '/occurrence'}" *ngIf="occurrence">
            <a href="javascript:void('Nota');" [routerLink]="['/occurrence']" class="nav-link align-items-center"
               style="gap: 20px"
               data-toggle="collapse"
               data-target="#idea2" aria-expanded="false"
               aria-controls="idea2"
               (click)="actionCloseCollapse()">
                <svg-icon src="assets/svg/occurrence.svg"></svg-icon>
                <span class="lbl">{{'ocorrencia'|translate}}</span>
            </a>
            <div class="collapse" id="idea2" data-parent="#accordionExample" *ngIf="dashboard">
                <a href="javascript:void(0);"
                   (click)="actionCloseCollapse()" [routerLink]="['/dashboard']"
                   [ngClass]="{'active':routerNotParm === '/dashboard'}"
                   class="nav-link text-white align-items-center"
                   style="gap: 20px">
                    <svg-icon src="assets/svg/dashboard.svg"></svg-icon>
                    <span class="lbl">Dashboard</span>
                </a>
            </div>
        </li>
        <li class="nav-item"
            [ngClass]="{'active':routerNotParm === '/access-control'||routerNotParm ==='/alarm'||routerNotParm ==='/cftv'}"
            *ngIf="segurancaeletronica">
            <a href="javascript:void(0);" class="nav-link text-white align-items-center" style="gap: 20px"
               data-toggle="collapse"
               data-target="#idea" aria-expanded="false"
               aria-controls="idea">
                <svg-icon src="assets/svg/videocam.svg"></svg-icon>
                <span class="lbl">{{'segurancaeletronica'|translate}}</span>
            </a>
            <div class="collapse" id="idea" data-parent="#accordionExample">
                <a href="javascript:void(0);"
                   (click)="actionCloseCollapse()" *ngIf="accessControl"
                   [routerLink]="['/access-control']"
                   [ngClass]="{'active':routerNotParm === '/access-control'}"
                   class="nav-link text-white align-items-center"
                   style="gap: 20px">
                    <svg-icon src="assets/svg/list.svg"></svg-icon>
                    <span class="lbl">{{'controleacesso'|translate}}</span>
                </a>
                <a href="javascript:void(0);" (click)="actionCloseCollapse()" *ngIf="alarm"
                   [routerLink]="['/alarm']"
                   [ngClass]="{'active':routerNotParm === '/alarm'}"
                   class="nav-link text-white align-items-center"
                   style="gap: 20px">
                    <svg-icon src="assets/svg/kamban.svg"></svg-icon>
                    <span class="lbl">{{"settings-alarm.alarm" | translate}}</span>
                </a>
                <a href="javascript:void(0);" (click)="actionCloseCollapse()" *ngIf="cftv"
                   [routerLink]="['/cftv']"
                   [ngClass]="{'active':routerNotParm === '/cftv'}"
                   class="nav-link text-white align-items-center"
                   style="gap: 20px">
                    <svg-icon src="assets/svg/kamban.svg"></svg-icon>
                    <span class="lbl">CFTV</span>
                </a>
            </div>
        </li>
        <li class="nav-item" [ngClass]="{'active':routerNotParm === '/physical-security'}" *ngIf="physicalSecurity">
            <a href="javascript:void('Nota');" [routerLink]="['/physical-security']" class="nav-link align-items-center"
               style="gap: 20px"
               (click)="actionCloseCollapse()">
                <svg-icon src="assets/svg/local-police.svg"></svg-icon>
                <span class="lbl">{{'segurancafisica'|translate}}</span>
            </a>
        </li>
        <li class="nav-item" *ngIf="gestaoR">
            <a href="{{isRiskManagement}}" target="_blank" class="nav-link align-items-center"
               style="gap: 20px"
               (click)="actionCloseCollapse()">
                <svg-icon src="assets/svg/query-stats.svg"></svg-icon>
                <span class="lbl">{{'gestaoriscos'|translate}}</span>
            </a>
        </li>
        <li class="nav-item" [ngClass]="{'active':routerNotParm === '/policies-procedures'}" *ngIf="policiesProcedures">
            <a href="javascript:void('Nota');" [routerLink]="['/policies-procedures']"
               class="nav-link align-items-center"
               style="gap: 20px"
               (click)="actionCloseCollapse()">
                <svg-icon src="assets/svg/newspaper.svg"></svg-icon>
                <span class="lbl">{{'poloiticaeprocedimentos'|translate}}</span>
            </a>
        </li>
        <li class="nav-item" [ngClass]="{'active': routerNotParm === '/truck' || routerNotParm ==='/services'}"
            *ngIf="praanchetaeletronica">
            <a href="javascript:void(0);" class="nav-link text-white align-items-center" style="gap: 20px"
               data-toggle="collapse"
               data-target="#clipboard" aria-expanded="false"
               aria-controls="clipboard">
                <svg-icon src="assets/svg/local-shipping.svg"></svg-icon>
                <span class="lbl">{{'praanchetaeletronica'|translate}}</span>
            </a>
            <div class="collapse" id="clipboard" data-parent="#accordionExample">
                <a href="javascript:void(0);" *ngIf="truck"
                   (click)="actionCloseCollapse()" [routerLink]="['/truck']"
                   class="nav-link text-white align-items-center"
                   style="gap: 20px">

                    <span class="lbl">{{"caminhoes" | translate}}</span>
                </a>
                <a href="javascript:void(0);" *ngIf="services"
                   (click)="actionCloseCollapse()" [routerLink]="['/services']"
                   class="nav-link text-white align-items-center"
                   style="gap: 20px">
                    <span class="lbl">{{"servicos" | translate}}</span>
                </a>
            </div>
        </li>


        <!--<li class="nav-item">
            <a href="javascript:void('Requests');" [routerLink]="['/solicitacoes']" class="nav-link text-white"
               (click)="actionCloseCollapse()">
                <span class="lbl">{{'sidebar.solicitacoes' | translate}}</span>
            </a>
        </li>-->

        <li class="nav-item" [ngClass]="{'active':routerNotParm === '/user'}" *ngIf="userM">
            <a href="javascript:void('User');" [routerLink]="['/user']" class="nav-link align-items-center"
               style="gap: 20px"
               (click)="actionCloseCollapse()">
                <svg-icon src="assets/svg/group.svg"></svg-icon>
                <span class="lbl">{{'sidebar.user' | translate}}</span>
            </a>
        </li>
        <li class="nav-item"
            [ngClass]="{'active':routerNotParm === '/settings/camera'||routerNotParm ==='/settings/access'||routerNotParm ==='settings/alarm'||routerNotParm ==='settings/block'||routerNotParm ==='settings/unity'}"
            *ngIf="settings">
            <a href="javascript:void(0);" class="nav-link text-white align-items-center" style="gap: 20px"
               data-toggle="collapse"
               data-target="#setting" aria-expanded="false"
               aria-controls="setting">
                <svg-icon src="assets/svg/settings.svg"></svg-icon>
                <span class="lbl">{{"sidebar.config" | translate}}</span>
            </a>
            <div class="collapse" id="setting" data-parent="#accordionExample">
                <a href="javascript:void(0);" *ngIf="settingsCamera"
                   (click)="actionCloseCollapse()" [routerLink]="['/settings/camera']"
                   class="nav-link text-white align-items-center"
                   style="gap: 20px">
                    <span class="lbl">{{"settings-camera.camera" | translate}}</span>
                </a>
                <a href="javascript:void(0);" *ngIf="settingsAccess"
                   (click)="actionCloseCollapse()" [routerLink]="['/settings/access']"
                   class="nav-link text-white align-items-center"
                   style="gap: 20px">
                    <span class="lbl">{{"settings-access.access" | translate}}</span>
                </a>
                <a href="javascript:void(0);" *ngIf="settingsAlarm"
                   (click)="actionCloseCollapse()" [routerLink]="['/settings/alarm']"
                   class="nav-link text-white align-items-center"
                   style="gap: 20px">
                    <span class="lbl">{{"settings-alarm.alarm" | translate}}</span>
                </a>
                <a href="javascript:void(0);" *ngIf="settingsBlock"
                   (click)="actionCloseCollapse()" [routerLink]="['/settings/block']"
                   class="nav-link text-white align-items-center"
                   style="gap: 20px">
                    <span class="lbl">{{"bloc" | translate}}</span>
                </a>
                <a href="javascript:void(0);" *ngIf="settingsUnity"
                   (click)="actionCloseCollapse()" [routerLink]="['/settings/unity']"
                   class="nav-link text-white align-items-center"
                   style="gap: 20px">
                    <span class="lbl">{{"settings-unity.unity" | translate}}</span>
                </a>
                <a href="javascript:void(0);" *ngIf="settingsGeneral"
                   (click)="actionCloseCollapse()" [routerLink]="['/settings/general']"
                   class="nav-link text-white align-items-center"
                   style="gap: 20px">
                    <span class="lbl">{{"settings-general.general" | translate}}</span>
                </a>
            </div>
        </li>


    </ul>
    <div class="logout d-lg-none">
        <a href="javascript:void('sair');" (click)="onLogout(user.isMicrosoft)">
            {{'sair' | translate}}
        </a>
    </div>

</nav>
