import {Component, OnInit, AfterViewInit} from '@angular/core';
import {BaseComponent} from "../../../base/base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {
    PhysicalSecurity,
    PhysicalSecurityGQL,
    PhysicalSecurityMember,
    PhysicalSecurityQuery, User
} from "../../../../generated/graphql";
import {QueryRef} from "apollo-angular";
import {environment} from "../../../../environments/environment";
import {UserService} from "../../../service/user.service";

@Component({
    selector: 'app-physical-security-detail',
    templateUrl: './physical-security-detail.component.html',
    styleUrls: ['./physical-security-detail.component.scss']
})
export class PhysicalSecurityDetailComponent extends BaseComponent implements AfterViewInit {
    user = new User();
    parmId: string;
    physicalSecurityQuery: QueryRef<PhysicalSecurityQuery>;
    physicalSecurity: PhysicalSecurity = new PhysicalSecurity();
    listMember: PhysicalSecurityMember[] = [];
    window = window;

    constructor(public router: Router,
                public activatedRoute: ActivatedRoute,
                private physicalSecurityGQL: PhysicalSecurityGQL,
                public userService: UserService,
                public translate: TranslateService) {
        super(router, translate);
    }

    ngAfterViewInit(): void {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
            }, error: err => super.onError(err)
        });
        this.activatedRoute.params.subscribe(data => {
            if (!this.isNullOrUndefined(data.id)) {
                this.parmId = data.id;
                this.physicalSecurityQuery = this.physicalSecurityGQL.watch({id: data.id});
                this.physicalSecurityQuery.valueChanges.subscribe(({data}) => {
                    this.physicalSecurity = data.physicalSecurity as PhysicalSecurity;
                    this.listMember = this.physicalSecurity.members;
                }, error => super.onError(error));
            }
        });
    }

    actionPdf() {
        const token = localStorage.getItem('token') as string;
        const id = this.parmId as string;
        const url = `${environment.base_url}/physical-security/pdf?id=${id}&token=${token}`;
        window.open(encodeURI(url));
    }

    actionUserProfile() {
        switch (this.user.profile) {
            case 'vigilant':
                return true;
            case 'lecturer':
                return true;
            case 'local-manager':
                return true;
            case 'corporate-operator':
                return true;
            case 'user':
                return true;
            case 'admin':
                return true;
        }
    }

}
