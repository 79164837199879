<a id="btnDownload" class="d-none" href="javascript:void('');" download>DownloadFakeBtn</a>
<div class="page">
    <div class="page-padding">
        <div class="page-header-custom2 mb-5">
            <div class="row">
                <div class="col-lg-6 border-right">
                    <div class="d-flex align-items-center" style="gap: 20px">
                        <svg-icon (click)="window.history.back()" src="assets/svg/arrow.svg" style="cursor: pointer"></svg-icon>
                        <h1 class="secondary "><span>Check list  <span
                                class="font-weight-bold mr-3">{{'controleacesso' | translate}}</span> </span>
                        </h1>
                    </div>
                </div>

                <div class="col" style="gap: 20px">
                    <div class="row">
                        <div class="col border-right">
                            <span>{{'responsible' | translate}}</span>
                            <p>{{checkList.checklist?.createdBy?.name}}</p>
                        </div>

                        <div class="col border-right">
                            <span>{{'unit' | translate}}</span>
                            <p>{{checkList.checklist?.unity?.name}}</p>
                        </div>

                        <div *ngIf="!isNullOrUndefined(checkList.checklist?.unity?.country?.flag)"
                             class="col-auto border-right">
                            <p>{{checkList.checklist?.unity?.country?.name}}</p>
                            <img *ngIf="!isNullOrUndefined(checkList.checklist?.unity?.country?.flag)"
                                 [src]="'assets/images/country/' + checkList.checklist?.unity?.country?.flag + '.svg'"
                                 alt="img"
                                 width="30px"/>
                        </div>

                        <div class="col d-flex flex-column justify-content-center">
                            <button (click)="actionExcel()" *ngIf="exportExcel" class="btn btn-gray-fill">
                                <svg-icon src="assets/svg/downloads.svg"></svg-icon>
                                Exportar Excel
                            </button>
                        </div>
                    </div>

                </div>
            </div>

        </div>

        <div class="page-filter page-input d-flex flex-wrap">
            <div class="input-material">
                <input id="name" [(ngModel)]="filter.name" class="form-control" type="text" required
                       [maxLength]="80"/>
                <label for="name">
                    <svg-icon src="assets/svg/search.svg"></svg-icon>
                    <span>{{'busca'|translate}}</span>
                </label>
            </div>
            <div class="input-material select flex-wrap">
                <select id="status" [(ngModel)]="filter.status" class="form-control">
                    <option [ngValue]="undefined">Todos</option>
                    <option *ngFor="let s of status" [value]="s">{{getTranslate(s)}}</option>
                </select>
                <label for="status">Status</label>
            </div>
            <div class="input-material select flex-wrap">
                <select id="bloc" [(ngModel)]="filter.blocId" class="form-control">
                    <option [ngValue]="undefined">Todos</option>
                    <option *ngFor="let b of blocs" [value]="b.id">{{b.name}}</option>
                </select>
                <label for="bloc">{{'bloc' | translate}}</label>
            </div>
            <div class="d-flex flex-wrap">
                <button (click)="onSearch()" class="btn btn-block text-warning btn-primary btn-material mb-0">
                    Buscar
                </button>
            </div>
        </div>


        <div class="table-responsive">
            <table class="table table_regular">
                <thead>
                <tr>
                    <th scope="col">Código</th>
                    <th scope="col">{{'local' | translate}}</th>
                    <th class="text-center" scope="col">{{'tipo' | translate}}</th>
                    <th class="text-center" scope="col"> {{'conditions' | translate}}</th>
                    <th class="text-center" scope="col">{{'term' | translate}}</th>
                    <th class="text-center" scope="col">Status</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let c of checkList.items |
                paginate: {itemsPerPage: checkList.PageSize,
                currentPage: checkList.currentPage,
                totalItems: checkList.totalCount }">
                    <td>{{c.access.code}}</td>
                    <td>{{c.access.place}}</td>
                    <td class="text-center">{{c.access.type}}</td>
                    <td class="text-center">
                        <div class="check-btn btn-success mx-auto disabled" *ngIf="c.condition == 'ok'">
                            <svg-icon src="assets/svg/check.svg"></svg-icon>
                        </div>
                        <div class="check-btn btn-warning mx-auto disabled" *ngIf="c.condition == 'fail'">
                            <svg-icon src="assets/svg/portable.svg"></svg-icon>
                        </div>
                        <div class="check-btn btn-danger mx-auto disabled" *ngIf="c.condition == 'error'">
                            <svg-icon src="assets/svg/close-secondary.svg"></svg-icon>
                        </div>
                    </td>
                    <td class="text-center">{{c.dateLimit | date:'dd/MM/yyyy HH:mm'}}</td>
                    <td class="text-center">
                        <button *ngIf="c.status == 'late'" (click)="onModalDetail(c)"
                                class="btn-terms btn btn-xs btn-danger btn-block text-white">
                            {{getTranslate(c.status)}}
                        </button>
                        <button *ngIf="c.status == 'pending'" (click)="onModalDetail(c)"
                                class="btn-terms btn btn-xs btn-warning btn-block text-gray-text">
                            {{getTranslate(c.status)}}
                        </button>
                        <button *ngIf="c.status == 'working'" (click)="onModalDetail(c)"
                                class="btn-terms btn btn-xs btn-info btn-block text-white">
                            {{getTranslate(c.status)}}
                        </button>
                        <button *ngIf="c.status == 'done'" (click)="onModalDetail(c)"
                                [disabled]="c.comments.length == 0"
                                class="btn-terms btn btn-xs btn-success btn-block text-white">
                            {{getTranslate(c.status)}}
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="text-right">
            <pagination-controls
                    [previousLabel]="labels.previousLabel"
                    [nextLabel]="labels.nextLabel"
                    [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                    [screenReaderPageLabel]="labels.screenReaderPageLabel"
                    [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                    (pageChange)="pageChanged($event)"></pagination-controls>
        </div>
    </div>
</div>


<div class="modal fade" id="statusModal" tabindex="-1" role="dialog" aria-labelledby="statusModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content p-0">
            <div class="modal-header">
                <h5 class="modal-title text-primary">
                    {{'mudançadestatus' | translate}}
                </h5>
                <button (click)="closeModal('modalStatus')" type="button" class="pt-0" data-dismiss="modal"
                        aria-label="Close">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body modal_small mh-0 mb-0 flex-column">
                <!--LOCAL-->
                <div class="card_status"
                     [ngClass]="{'bg-warning':selectedItem.status =='pending',
                             'bg-info':selectedItem.status =='working',
                             'bg-danger':selectedItem.status =='late',
                             'bg-success':selectedItem.status =='done'}">
                    <p>{{'atualizeostatusdatarefasemprequepossível' | translate}}</p>
                    <div class="input-material flex-grow-1 mb-0">
                        <select id="statusSelected" [disabled]="selectedItem.status == 'done'"
                                [(ngModel)]="statusCheckList" class="form-control">
                            <option *ngFor="let s of status" [value]="s">{{getTranslate(s)}}</option>
                        </select>
                        <label class="disabled" for="statusSelected">Status</label>
                    </div>
                </div>
                <div *ngIf="selectedItem.status != 'done'" class="input-material mt-3 w-100 mb-0">
                    <textarea id="comment" [(ngModel)]="pushComment" required maxlength="250"
                              class="input-material form-control"></textarea>
                    <label for="name">{{'escrevaaquiseucomentario' | translate}}</label>
                </div>

                <div class="d-flex justify-content-center" *ngIf="isNullOrUndefined(selectedItem.comments)">
                    <p class="no_information">
                        {{'nenhumcomentárioencontrado' | translate}}</p>
                </div>

                <div *ngIf="!isNullOrUndefined(selectedItem.comments)">
                    <p class="logs_title">{{'logdecomentários' | translate}}</p>
                    <div class="comment">
                        <div class="comment_list mr-2" *ngFor="let comment of selectedItem.comments">
                            <div class="comment_header">
                                <p class="">{{comment.createdAt | date:'dd/MM/yyyy'}} -
                                    <b>{{comment.createdBy?.name}}</b>
                                </p>
                                {{getTranslate(comment.status)}}
                            </div>
                            <p class="comment_content">{{comment.comment}}</p>
                        </div>
                    </div>

                </div>
                <div *ngIf="selectedItem.status != 'done'" class="d-flex justify-content-center">
                    <a (click)="onSave()" href="javascript:void('modal')" class="col-6 btn btn-secondary mt-3">
                        {{'salvar'|translate}}
                    </a>
                </div>

            </div>
        </div>
    </div>
</div>




