<div *ngIf="isNullOrUndefined(selectedBloc)" class="page">
    <div class="page-padding">
        <div class="page-header-custom secondary">
            <svg-icon (click)="window.history.back()" class="is-cursor" src="assets/svg/arrow.svg"></svg-icon>
            <h1 class="page-title secondary"><span>Check list</span> {{'controleacesso' | translate}}</h1>
        </div>
        <div class="row page-container mt-4">
            <div class="col-sm-6 col-md-3 col-xxl-2" *ngFor="let u of checklistResult.blocs">
                <div class="page-card block" (click)="actionSelectBloc(u)">
                    <div class="d-flex align-items-center">
                        <h2 class="block-title">{{u.name}}</h2>
                        <svg-icon *ngIf="u.done == u.total" src="assets/svg/check-circle.svg"></svg-icon>
                    </div>
                    <div class="d-flex justify-content-between mt-3">
                        <p class="block-text">Total
                            <span>{{u.done}}</span>
                            <span>/{{u.total}}</span>
                        </p>
                        <p *ngIf="u.done == u.total" class="block-text">{{u.lastItemDate | date: 'dd/MM/yyyy'}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!isNullOrUndefined(selectedBloc, false)" class="page">
    <div class="page-padding">
        <div (click)="actionClearSelectedBloc()" class="page-header-custom secondary">
            <svg-icon src="assets/svg/arrow.svg" style="cursor:pointer"></svg-icon>
            <h1 class="page-title secondary"><span>Check list  {{'controleacesso' | translate}} </span>| {{selectedBloc.name}}</h1>
        </div>
        <div class="table-responsive">
            <table class="table table_regular">
                <thead>
                <tr>
                    <th scope="col">Código</th>
                    <th scope="col">Local</th>
                    <th class="text-center" scope="col">Tipo</th>
                    <th class="text-center" scope="col">{{'conditions' | translate}}</th>
                    <th class="text-center" scope="col">{{'comentario' | translate}}</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let a of selectedBloc.items">
                    <td>{{a.access?.code}}</td>
                    <td>{{a.access?.place}}</td>
                    <td class="text-center">{{a.access?.type}}</td>
                    <td>
                        <div class="d-flex justify-content-center" style="gap: 10px">
                            <div class="check-btn" (click)="a.condition = 'ok'"
                                 [ngClass]="{'btn-success': a.condition === 'ok'}">
                                <svg-icon src="assets/svg/check.svg"></svg-icon>
                            </div>
                            <div class="check-btn" (click)="a.condition = 'fail'"
                                 [ngClass]="{'btn-warning': a.condition === 'fail'}">
                                <svg-icon src="assets/svg/portable.svg"></svg-icon>
                            </div>
                            <div (click)="a.condition = 'error'" class="check-btn"
                                 [ngClass]="{'btn-danger': a.condition === 'error'}">
                                <svg-icon src="assets/svg/close-secondary.svg"></svg-icon>
                            </div>
                        </div>
                    </td>
                    <td class="text-center">
                        <div *ngIf="a.condition == 'error'||a.condition == 'fail'"
                             class="d-flex justify-content-center">
                            <div (click)="actionOpenCommentModal(a)" class="check-btn"
                                 [ngClass]="{'btn-secondary': !isNullOrUndefined(a.comments)}">
                                <svg-icon src="assets/svg/comment.svg"></svg-icon>
                            </div>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <a (click)="actionSave()" class="col-12 btn btn-secondary mt-3">
            {{'salvar'|translate}}
        </a>
    </div>
</div>


<app-modal-comment [commentModel]="commentModel" (returnComment)="actionAddComment($event)"></app-modal-comment>
