import {AfterViewInit, Component} from '@angular/core';
import {BaseComponent} from '../../../base/base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {
    CreateServiceRequestGQL, CreateServiceRequestInput,
    CreateTruckCheckListObject, GetUnitiesGQL, GetUnitiesQuery, PhysicalSecurityMember,
    RemoveTruckGQL,
    SearchServiceRequestGQL,
    SearchServiceRequestInput,
    SearchServiceRequestQuery, ServicePage,
    ServiceRequest,
    ServiceRequestGQL,
    ServiceRequestHelper,
    ServiceRequestQuery,
    Truck,
    TruckHelper,
    TruckQuery,
    TruckReceipt, Unity, UpdateServiceRequestGQL, UpdateServiceRequestInput, User
} from '../../../../generated/graphql';
import {TranslateService} from '@ngx-translate/core';
import {QueryRef} from 'apollo-angular';
import moment from 'moment';
import {UserService} from '../../../service/user.service';
import {ExcelService} from '../../../service/excel.service';

declare var $: any;

@Component({
    selector: 'app-services-create',
    templateUrl: './services-create.component.html',
    styleUrls: ['./services-create.component.scss']
})
export class ServicesCreateComponent extends BaseComponent implements AfterViewInit {
    window = window;
    user: User = new User();

    paramId = '';

    modelQuery: QueryRef<ServiceRequestQuery>;

    searchServiceRequestQuery: QueryRef<SearchServiceRequestQuery>;
    listService: ServicePage = new ServicePage();
    filter: SearchServiceRequestInput = new SearchServiceRequestInput();

    model: ServiceRequest = new ServiceRequest();
    modelUserVictim: ServiceRequest = new ServiceRequest();
    modelHelper: ServiceRequestHelper = new ServiceRequestHelper();

    entranceMotive = 'shipment';
    unities: Array<Unity>;
    unityQuery: QueryRef<GetUnitiesQuery>;

    profile: boolean = false;
    unityID: string;
    labelUnity: boolean;

    file: File = null;

    constructor(public router: Router,
                private searchServiceRequestGLQ: SearchServiceRequestGQL,
                private removeTruckGQL: RemoveTruckGQL,
                private createServiceRequestGQL: CreateServiceRequestGQL,
                private updateServiceRequestGQL: UpdateServiceRequestGQL,
                private serviceRequestGQL: ServiceRequestGQL,
                public userService: UserService,
                private getUnitiesGQL: GetUnitiesGQL,
                public activatedRoute: ActivatedRoute,
                private excelService: ExcelService,
                public translate: TranslateService) {
        super(router, translate);
        this.model.helper = [];
        this.model.meal = 'N'; // CONFIGURAÇÃO INICIAL PARA 'NEGATIVO'
    }

    ngAfterViewInit(): void {
        this.getUnity();
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
                this.profile = this.handlerProfile();
                if (this.actionUserProfile() === false) {
                    this.unityID = this.user.unity.id;
                    this.getUnity();
                }
            }, error: err => super.onError(err)
        });
        this.actionUserProfileBTN();
        this.activatedRoute.params.subscribe(u => {
            if (!this.isNullOrUndefined(u.id)) {
                this.paramId = u.id;
                if (this.paramId !== 'new') {
                    this.modelQuery = this.serviceRequestGQL.watch({data: this.paramId});
                    this.modelQuery.valueChanges.subscribe(({data}) => {
                        this.model = data.serviceRequest as ServiceRequest;
                        this.initDate();
                    });
                } else {
                    this.model.entranceDate = moment().utcOffset(0, true).format();
                    this.model.exitDate = moment().utcOffset(0, true).format();
                    this.initDate();
                }
            }
        });
        this.searchServiceRequestQuery = this.searchServiceRequestGLQ.watch({data: this.filter});
        this.searchServiceRequestQuery.valueChanges.subscribe(({data}) => {
            this.listService = data.searchServiceRequest as ServicePage;
        }, error1 => super.onError(error1));
        this.unityQuery = this.getUnitiesGQL.watch();
        this.unityQuery.valueChanges.subscribe(({data}) => {
            this.unities = data.unities as Array<Unity>;
        });
    }

    getUnity() {
        this.unityQuery = this.getUnitiesGQL.watch();
        this.unityQuery.valueChanges.subscribe(({data}) => {
            this.unities = data.unities as Array<Unity>;
        });
    }

    initDate() {
        this.setDatePicker('date-picker', (start, end) => {
            this.model.entranceDate = start.utcOffset(0, true).format();
        }, this.model.entranceDate, true, 'DD/MM/YYYY - HH:mm', true);
        this.setDatePicker('date-picker-exit', (start, end) => {
            this.model.exitDate = start.utcOffset(0, true).format();
        }, this.model.exitDate, true, 'DD/MM/YYYY - HH:mm', true);
    }

    actionSave() {
        const arrValidateFields = [
            {value: this.model.area, text: `Àrea de Serviço<br>`},
            {value: this.model.entranceDate, text: `Data de Entrada<br>`},
            {value: this.model.exitDate, text: `Data de Saída<br>`},
            {value: this.model.name, text: `Nome prestador <br>`},
            {value: this.model.cpf, text: `ID <br>`},
            {value: this.model.cnpj, text: `CNPJ <br>`},
            {value: this.model.company, text: `Empresa <br>`},
            // {value: this.model.vehicle, text: `Veículo <br>`},
            // {value: this.model.licensePlate, text: `Placa do Veículo <br>`},
            {value: this.model.requestNumber, text: `Número do pedido <br>`}
        ];
        if (this.actionUserProfile()) {
            arrValidateFields.push({value: this.unityID, text: `Unidade<br>`});
        }
        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('camposobrigatorios')} <br> ${stringError}`);
            return;
        }
        // if (!this.isCPFValid(this.model.cpf)) {
        //     return;
        // }

        if (this.model.meal === 'S') {
            if (this.isNullOrUndefined(this.model.mealCostCenter) || this.isNullOrUndefined(this.file)) {
                const stringErrorMeal = this.translate.instant('mealCostCenter') + ' <br /> ' + this.translate.instant('mealContract');
                this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('camposobrigatorios')} <br> ${stringErrorMeal}`);
                return;
            }
        }

        if (this.isNullOrUndefined(this.model.id)) {
            this.onCreateServiceRequest();
        } else {
            this.onUpdateServiceRequest();
        }
    }

    onCreateServiceRequest() {
        const a: CreateServiceRequestInput = new CreateServiceRequestInput();
        a.unity = {id: this.unityID},
            a.area = this.model.area;
        a.entranceDate = this.model.entranceDate;
        a.exitDate = this.model.exitDate;
        a.name = this.model.name;
        a.cpf = this.model.cpf;
        a.cnpj = this.model.cnpj;
        a.company = this.model.company;
        a.vehicle = this.model.vehicle;
        a.licensePlate = this.model.licensePlate;
        a.helper = this.model.helper;
        a.requestNumber = this.model.requestNumber;
        a.meal = this.model.meal;
        a.mealCostCenter = this.model.mealCostCenter;
        this.createServiceRequestGQL.mutate({data: a}).subscribe((result) => {

            if (!this.isNullOrUndefined(this.file)) {
                this.excelService.upload(this.file, `/upload/service-request/meal-contract/${result.data.createServiceRequest.id}`).subscribe({
                    next: data2 => {
                        this.router.navigate(['/services']);
                        this.showMessage(this.translate.instant('success'), this.translate.instant('cadastrorealizado'), 'success');
                    }, error: err => super.onError(err)
                });
            } else {
                this.router.navigate(['/services']);
                this.showMessage(this.translate.instant('success'), this.translate.instant('cadastrorealizado'), 'success');
            }
        });
    }


    onUpdateServiceRequest() {
        const a: UpdateServiceRequestInput = new UpdateServiceRequestInput();
        a.id = this.model.id;
        a.area = this.model.area;
        a.entranceDate = this.model.entranceDate;
        a.exitDate = this.model.exitDate;
        a.name = this.model.name;
        a.cpf = this.model.cpf;
        a.cnpj = this.model.cnpj;
        a.company = this.model.company;
        a.vehicle = this.model.vehicle;
        a.licensePlate = this.model.licensePlate;
        a.helper = this.model.helper;
        a.meal = this.model.meal;
        a.mealCostCenter = this.model.mealCostCenter;
        a.helper.map((x) => {
            delete x['id'];
            delete x['__typename'];
        });
        this.updateServiceRequestGQL.mutate({data: a}).subscribe((result) => {
            if (!this.isNullOrUndefined(this.file)) {
                this.excelService.upload(this.file, `/upload/service-request/meal-contract/${this.model.id}`).subscribe({
                    next: data2 => {
                        this.router.navigate(['/services']);
                        this.showMessage(this.translate.instant('success'), this.translate.instant('agendamentoalterado'), 'success');
                    }, error: err => super.onError(err)
                });
            } else {
                this.router.navigate(['/services']);
                this.showMessage(this.translate.instant('success'), this.translate.instant('agendamentoalterado'), 'success');
            }
        });
    }

    onSearch() {
        this.searchServiceRequestQuery.refetch({data: this.filter});
    }

    onClearModel(event: FocusEvent, str: string) {
        setTimeout(() => {
            const a = $(event.target).val();
            if (super.isNullOrUndefined(a)) {
                this.model[str] = '';
            }
        });
    }

    onClearVictim(event: FocusEvent, str: string) {
        setTimeout(() => {
            const a = $(event.target).val();
            if (super.isNullOrUndefined(a)) {
                this.modelUserVictim[str] = '';
            }
        });
    }

    actionHelperAdd() {
        const arrValidateFields = [
            {value: this.modelHelper.name, text: `Nome<br>`},
            {value: this.modelHelper.doc, text: `ID<br>`}
        ];
        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('camposobrigatorios')} <br> ${stringError}`);
            return;
        }
        // VALIDAR ID
        if (super.isNullOrUndefined(stringError)) {
            let checkId = null;
            const regex = / {2}/;
            if (regex.test(this.modelHelper.doc)) {
                checkId = 'id';
            }
            if (!super.isNullOrUndefined(checkId)) {
                this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('valorInvalido')} <br> ${checkId}`);
                return;
            }
        }
        // if (!this.isCPFValid(this.modelHelper.doc)) {
        //     return;
        // }
        const el = this.model.helper.filter(x => x.doc === this.modelHelper.doc);
        if (el[0]) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('cpfcadastrado'));
            return;
        }
        this.model.helper.push(this.modelHelper);
        this.modelHelper = new ServiceRequestHelper();
    }

    actionRemoveHelper(x: ServiceRequestHelper) {
        this.confirmMessage(this.translate.instant('atencao'), this.translate.instant('removerajudante'), () => {
            this.model.helper = this.model.helper.filter(c => c.doc !== x.doc);
        });
    }

    handlerProfile() {
        return this.user.profile === 'vigilant' || this.user.profile === 'lecturer';
    }

    actionUserProfile() {
        switch (this.user.profile) {
            case 'admin':
                return true;
            case 'local-manager':
                return false;
            case 'user':
                return false;
            case 'vigilant-leader':
                return false;
            case 'vigilant-conductor':
                return false;
            case 'vigilant':
                return false;
            case 'lecturer':
                return false;
            case 'corporate-operator':
                return false;
            case 'local-operator':
                return false;
            case 'transport':
                return false;
        }
    }

    actionUserProfileBTN() {
        switch (this.user.profile) {
            case 'admin':
                this.labelUnity = true;
                break;
            case 'local-manager':
                this.labelUnity = false;
                break;
            case 'user':
                this.labelUnity = false;
                break;
            case 'vigilant-leader':
                this.labelUnity = false;
                break;
            case 'vigilant-conductor':
                this.labelUnity = false;
                break;
            case 'vigilant':
                this.labelUnity = false;
                break;
            case 'lecturer':
                this.labelUnity = false;
                break;
            case 'corporate-operator':
                this.labelUnity = false;
                break;
            case 'local-operator':
                this.labelUnity = false;
                break;
            case 'transport':
                this.labelUnity = false;
                break;
        }
    }

    actionImport(event: any) {
        const files = event.target.files;
        if (files.length > 0) {
            if (files[0].size > 60000000) {
                super.showMessage(this.translate.instant('baseComponent.warningAttention'),
                    this.translate.instant('baseComponent.warningMsgUploadFileMaxSize'), 'info');
                return;
            }
        }
        const reader = new FileReader();
        reader.onload = (file) => {
            // this.pathImage = file.target.result;
            /*this.pathImageObj[element].path = file.target.result;
            this.pathImageObj[element].name = files[0].name;
            this.listFiles.push({file: files[0], path: file.target.result, type});*/
        };
        reader.readAsDataURL(event.target.files[0]);
        this.file = files[0];

        console.log('upload');
        console.log(this.file);

        /*this.excelService.upload(this.file, `service-request/meal-contract`).subscribe({
            next: data2 => {
                this.file = null;
                // this.listMember = data2 as PhysicalSecurityMember[];
            }, error: err => super.onError(err)
        });*/
    }

    onChangeMeal($event: Event) {
        const elementTarget: any = $event.srcElement;
        this.model.meal = elementTarget?.checked ? 'S' : 'N';
    }
}
