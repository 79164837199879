<div class="page">
    <div class="page-padding">
        <div class="page-header-custom secondary mb-5">
            <svg-icon (click)="window.history.back()" src="assets/svg/arrow.svg" class="is-cursor"></svg-icon>
            <h1 *ngIf="isNullOrUndefined(physicalSecurity.id)" class="page-title secondary">
                <span>{{'nova'|translate}} </span>{{'empresa'|translate}}</h1>
            <h1 *ngIf="!isNullOrUndefined(physicalSecurity.id)" class="page-title secondary"><span>Segurança </span>Fisica
            </h1>
        </div>
        <div class="row  flex-xs-row justify-content-between align-items-center">
            <div class="col-xs-6 col-sm-4">
                <div class="page-card p-3 secondary">
                    <dl class="d-flex">
                        <dt class="page-lbl">Status <br><span>da empresa</span></dt>
                        <dd class="page-value align-items-center justify-content-end flex-grow-1">
                            <label class="switch mb-0">
                                <input type="checkbox" [(ngModel)]="physicalSecurity.companyStatus"
                                       name="checkbox">
                                <span class="slider round"></span>
                            </label>
                        </dd>
                    </dl>
                </div>
            </div>
            <div class="btn_section">
                <div class="btn">
                    <label class="btn btn-gray-fill mb-4  page-content__upload__input">
                        <svg-icon src="assets/svg/downloads.svg"></svg-icon>
                        <a href="assets/files/modelo_physical.xlsx" class="text-primary ">Exportar Modelo</a>

                    </label>
                </div>
                <div class="btn">
                    <label for="doc-1" class="btn btn-gray-fill mb-4  page-content__upload__input">
                        <svg-icon [svgStyle]="{'transform':'rotate(180deg)'}" src="assets/svg/downloads.svg"></svg-icon>
                        <span class="text-primary ">Importar Escala</span>
                        <input type="file" id="doc-1" name="doc-1" required
                               onclick="this.value = null"
                               accept=".xlsx,.xls"
                               (change)="actionImport($event, 'register-receipt', 'primary')"/>
                    </label>
                </div>
            </div>

        </div>
        <div class="page-card secondary">
            <div class="row" style="row-gap: 20px">
                <p class="col-sm-3 page-lbl secondary">{{'informacoes'|translate}}
                    <br><span>{{'daempresa'|translate}}</span></p>
                <div class="col">
                    <div class="row" style="row-gap: 20px">
                        <div class="col-lg-7 page-value">
                            <div class="input-material">
                                <input id="name" maxlength="100" [(ngModel)]="physicalSecurity.name"
                                       class="form-control" type="text"
                                       required/>
                                <label for="name">
                                    <span>*{{'nomeempresa'|translate}}</span>
                                </label>
                            </div>
                            <div class="row flex-grow-1" style="row-gap: 20px">
                                <div class="col-xs-6">
                                    <div class="input-material">
                                        <input id="segment" maxlength="50" [(ngModel)]="physicalSecurity.segment"
                                               class="form-control"
                                               type="text" required/>
                                        <label for="segment">
                                            <span>*{{'segmento'|translate}}</span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-xs-6">
                                    <div class="input-material">
                                        <input id="phone" mask="(00) 00000-0000 || (00) 0000-0000"
                                               [(ngModel)]="physicalSecurity.phone" class="form-control"
                                               type="text" required/>
                                        <label for="phone">
                                            <span>*{{'phone'|translate}}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 page-value">
                            <div class="input-material w-100">
                                <input id="cnpj" mask="00.000.000/0000-00" [(ngModel)]="physicalSecurity.cnpj"
                                       class="form-control" type="text"
                                       required/>
                                <label for="cnpj">
                                    <span>*CNPJ</span>
                                </label>
                            </div>
                            <div class="input-material w-100">
                                <input id="amount" [(ngModel)]="physicalSecurity.stationNumber" class="form-control"
                                       type="number" required/>
                                <label for="amount">
                                    <span>*Quantidade de postos</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="page-card secondary">
            <div class="row" style="row-gap: 20px">
                <p class="col-sm-3 page-lbl secondary">{{'informacoes'|translate}}
                    <br><span>{{'employee' | translate}}</span></p>
                <div class="col">
                    <div class="row">
                        <div class="col">
                            <div class="input-material">
                                <input id="search" #search (keyup)="onKey(search.value)"
                                       [mask]="this.translate.currentLang==='pt'? '000.000.000-00' : ''"
                                       class="form-control"
                                       type="text" required/>
                                <label for="search">
                                    <span>{{'buscar'|translate}} {{'cpf'|translate}}</span>
                                </label>
                            </div>
                        </div>
                        <div class="col-auto">
                            <a (click)="newHelper()" class="btn btn-lg btn-warning btn-block mb-0">
                                <div class="d-flex align-items-center" style="gap: 10px">
                                    <svg-icon src="assets/svg/plus-circle.svg"></svg-icon>
                                    <span> funcionario</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-4" *ngIf="!isNullOrUndefined(listMember)">
                <div class="col offset-sm-3">
                    <div class="table-responsive">
                        <table class="table js-filter-sidebar">
                            <thead>
                            <tr>
                                <th scope="col">{{'nome'|translate}}</th>
                                <th scope="col">{{'cpf'|translate}}</th>
                                <th scope="col">Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr class="js-filter-sidebar-item" *ngFor="let m of listMember, let i = index">
                                <td>{{m.name}}</td>
                                <td>{{this.translate.currentLang === 'pt' ? (m.document| mask:'000.000.000-00') : (m.document)}}</td>
                                <td>
                                    <label class="switch mb-0">
                                        <input type="checkbox" [(ngModel)]="m.status" name="checkbox">
                                        <span class="slider round"></span>
                                    </label>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <a (click)="actionSave()" class="btn btn-warning btn-lg btn-block flex-grow-1"
           style="max-width: 321px;margin: 0 auto">
            Salvar
        </a>
    </div>
</div>


<div class="modal fade" id="modalEmployee" tabindex="-1" role="dialog" aria-labelledby="userModal"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content p-0">
            <div class="modal-header">
                <h5 class="modal-title text-primary">
                    {{'addfuncionario'|translate}}
                </h5>
                <button type="button" class="pt-0" (click)="closeModal('modalEmployee')" data-dismiss="modal"
                        aria-label="Close">
                    <span aria-hidden="true">X</span>
                </button>
            </div>
            <div class="modal-body mh-0 mb-0">

                <div class="row mb-0">
                    <div class="col-12">
                        <div class="input-material w-100 flex-grow-1">
                            <input id="name_user" [(ngModel)]="member.name" maxlength="100" class="form-control"
                                   type="text" required/>
                            <label for="name_user">
                                <span>*Nome do funcionário</span>
                            </label>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="input-material w-100 flex-grow-1">
                            <input id="cpf" [(ngModel)]="member.document" class="form-control"
                                   [mask]="this.translate.currentLang==='pt'? '000.000.000-00' : ''"
                                   type="text" required/>
                            <label for="cpf">
                                <span>*{{'cpf' | translate}}</span>
                            </label>
                        </div>
                    </div>

                </div>
                <div class="row">
                    <div class="col-12">
                        <a (click)="pushMemberList()" class="btn btn-block btn-warning btn-lg">
                            <span>Adicionar</span>
                        </a>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
