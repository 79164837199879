import {Component, OnInit, AfterViewInit} from '@angular/core';
import {BaseComponent} from '../../../base/base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {
    PhysicalSecurityPage, RemovePhysicalSecurityGQL,
    SearchPhysicalSecurityGQL, SearchPhysicalSecurityInput,
    SearchPhysicalSecurityQuery, User
} from '../../../../generated/graphql';
import {QueryRef} from 'apollo-angular';
import moment from 'moment';
import {ExcelService} from '../../../service/excel.service';
import {UserService} from "../../../service/user.service";

@Component({
    selector: 'app-physical-security-home',
    templateUrl: './physical-security-home.component.html',
    styleUrls: ['./physical-security-home.component.scss']
})
export class PhysicalSecurityHomeComponent extends BaseComponent implements AfterViewInit {
    user = new User();
    searchPhysicalSecurityQuery: QueryRef<SearchPhysicalSecurityQuery>;
    security: PhysicalSecurityPage = new PhysicalSecurityPage();
    filter: SearchPhysicalSecurityInput = new SearchPhysicalSecurityInput();

    labelCountry: boolean;
    labelUnity: boolean;
    exportExcel: boolean;
    actionNew: boolean;
    actionUpdate: boolean;

    constructor(public router: Router,
                public activatedRoute: ActivatedRoute,
                public translate: TranslateService,
                private searchPhysicalSecurityGQL: SearchPhysicalSecurityGQL,
                private removePhysicalSecurityGQL: RemovePhysicalSecurityGQL,
                public userService: UserService,
                private excelService: ExcelService
    ) {
        super(router, translate);
    }

    ngAfterViewInit(): void {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
                this.actionUserProfileBTN();
                this.actionDate();
                this.actionList();
            }, error: err => super.onError(err)
        });
    }

    actionList() {
        this.filter.page = 1;
        this.searchPhysicalSecurityQuery = this.searchPhysicalSecurityGQL.watch({data: this.filter});
        this.searchPhysicalSecurityQuery.valueChanges.subscribe(({data}) => {
            this.security = data.searchPhysicalSecurity as PhysicalSecurityPage;
            this.setQueryString();
        }, error => super.onError(error));
    }

    actionFilter() {
        this.searchPhysicalSecurityQuery.refetch({data: this.filter}).then(() => {
            this.setQueryString();
        });
    }

    actionDate() {
        this.filter.start = moment().subtract(30, 'days').utcOffset(0, true).startOf('day').format();
        this.filter.end = moment().utcOffset(0, true).endOf('day').format();
        this.getQueryString();
        this.setDatePickerRange('js-picker-period', (start, end) => {
            this.filter.start = start.utcOffset(0, true).format();
            this.filter.end = end.utcOffset(0, true).format();
        }, this.filter.start, this.filter.end);
    }

    actionDelete(id: string) {
        super.confirmMessage(this.translate.instant('atencao'), this.translate.instant('desejaexcluiressaempresa'), () => {
            this.removePhysicalSecurityGQL.mutate({id: id}).subscribe((result) => {
                super.showMessage(this.translate.instant('success'), this.translate.instant('empresaexcluida'), 'success');
                this.actionFilter();
            });
        });
    }

    pageChanged(event) {
        this.filter.page = event;
        this.actionFilter();
    }

    actionExcel() {
        this.excelService.getExcelPhysicalSecurity(this.filter).subscribe({
            next: data => {
                this.downloadHandlerFile(data);
            }, error: err => super.onError(err)
        });
    }

    actionUserProfile() {
        switch (this.user.profile) {
            case 'admin':
                return true;
            case 'local-manager':
                return false;
            case 'user':
                return false;
            case 'vigilant-leader':
                return false;
            case 'vigilant-conductor':
                return false;
            case 'vigilant':
                return false;
            case 'lecturer':
                return false;
            case 'corporate-operator':
                return false;
            case 'local-operator':
                return false;
        }
    }

    actionUserProfileBTN() {
        switch (this.user.profile) {
            case 'admin':
                this.labelCountry = true;
                this.labelUnity = true;
                this.exportExcel = true;
                this.actionNew = true;
                this.actionUpdate = true;
                break;
            case 'local-manager':
                this.labelCountry = false;
                this.labelUnity = false;
                this.exportExcel = true;
                this.actionNew = true;
                this.actionUpdate = true;
                break;
            case 'user':
                this.labelCountry = false;
                this.labelUnity = false;
                this.exportExcel = false;
                this.actionNew = false;
                this.actionUpdate = false;
                break;
            case 'vigilant-leader':
                this.labelCountry = false;
                this.labelUnity = false;
                this.exportExcel = false;
                this.actionNew = false;
                this.actionUpdate = false;
                break;
            case 'vigilant-conductor':
                this.labelCountry = false;
                this.labelUnity = false;
                this.exportExcel = false;
                this.actionNew = false;
                this.actionUpdate = false;
                break;
            case 'vigilant':
                this.labelCountry = false;
                this.labelUnity = false;
                this.exportExcel = false;
                this.actionNew = false;
                this.actionUpdate = false;
                break;
            case 'lecturer':
                this.labelCountry = false;
                this.labelUnity = false;
                this.exportExcel = false;
                this.actionNew = false;
                this.actionUpdate = false;
                break;
            case 'corporate-operator':
                this.labelCountry = false;
                this.labelUnity = false;
                this.exportExcel = false;
                this.actionNew = false;
                this.actionUpdate = false;
                break;
            case 'local-operator':
                this.labelCountry = false;
                this.labelUnity = false;
                this.exportExcel = false;
                this.actionNew = false;
                this.actionUpdate = false;
                break;
        }
    }

    getQueryString() {
        this.activatedRoute.queryParams.subscribe((params) => {
            this.filter.start = params.start.replace('Z', '');
            this.filter.end = params.end.replace('Z', '');
        });
    }

    setQueryString() {
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {start: this.filter.start || null, end: this.filter.end || null}, // Remove o parâmetro se vazio
            queryParamsHandling: 'merge', // Mantém outros query params
        });
    }
}
