import {AfterViewInit, Component, OnDestroy} from '@angular/core';
import {BaseComponent} from '../../../base/base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {
    CreateTruckGQL, GetUnitiesByCountryGQL, GetUnitiesByCountryQuery, GetUnitiesGQL, GetUnitiesQuery,
    InspectTruckGQL,
    InspectTruckInput,
    InspectTruckTruckInput,
    ReleaseTruckGQL,
    ReleaseTruckInput,
    ReleaseTruckReceiptInput,
    ReleaseTruckReceiptReceiptInput,
    ReleaseTruckTruckInput,
    RemoveTruckGQL,
    SearchTruckGQL,
    SearchTruckInput,
    SearchTruckQuery,
    Truck,
    TruckInspection,
    TruckInspectionReceipt, TruckPage,
    TruckReceipt,
    TruckRelease, Unity, User
} from '../../../../generated/graphql';
import {QueryRef} from 'apollo-angular';
import moment from 'moment';
import {ExcelService} from '../../../service/excel.service';
import {UserService} from '../../../service/user.service';

@Component({
    selector: 'app-trucks-home',
    templateUrl: './trucks-home.component.html',
    styleUrls: ['./trucks-home.component.scss']
})

export class TrucksHomeComponent extends BaseComponent implements AfterViewInit, OnDestroy {
    user = new User();
    trucks: TruckPage = new TruckPage();
    filter: SearchTruckInput = new SearchTruckInput();
    searchTruckQuery: QueryRef<SearchTruckQuery>;
    checkOk = 'ok';
    statusTruck = 'liberate';

    inspectTruck: TruckInspection = new TruckInspection();
    releaseTruck: TruckRelease = new TruckRelease();
    receipt: TruckReceipt = new TruckReceipt();
    sealNumberSelected: string;

    newMessage: any;
    indexSelected: number;

    thumbSeal: any = null;
    fileSeal: File = null;

    thumbLicensePlate: any = null;
    fileLicensePlate: File = null;

    unities: Array<Unity>;
    unityQuery: QueryRef<GetUnitiesQuery>;

    labelUnity: boolean;
    actionNew: boolean;
    prohibited: boolean;
    lading: boolean;
    exit: boolean;
    exportExcel: boolean;

    constructor(public router: Router,
                private activatedRoute: ActivatedRoute,
                private searchTruckGQL: SearchTruckGQL,
                private removeTruckGQL: RemoveTruckGQL,
                private inspectTruckGQL: InspectTruckGQL,
                private releaseTruckGQL: ReleaseTruckGQL,
                private createTruckGQL: CreateTruckGQL,
                private excelService: ExcelService,
                public userService: UserService,
                private getUnitiesGQL: GetUnitiesGQL,
                public translate: TranslateService) {
        super(router, translate);
    }

    ngOnDestroy(): void {
        this.destroyModal('inspectTruck');
        this.destroyModal('releaseTruck');
        this.destroyModal('commentTruck');
        this.destroyModal('commentTruckSeal');
    }

    ngAfterViewInit(): void {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
            }, error: err => super.onError(err)
        });
        this.actionUserProfileBTN();
        if (this.actionUserProfile() === false) {
            this.filter.unity = this.user.unity.id;
        }
        this.filter.start = moment().subtract(6, 'days').utcOffset(0, true).startOf('day').format();
        this.filter.end = moment().utcOffset(0, true).endOf('day').format();
        this.getQueryString();
        this.setDatePickerRange('date-picker', (start, end, label) => {
            this.filter.start = start.utcOffset(0, true).format();
            this.filter.end = end.utcOffset(0, true).format();
        }, this.filter.start, this.filter.end);
        this.searchTruckQuery = this.searchTruckGQL.watch({data: this.filter});
        this.searchTruckQuery.valueChanges.subscribe(({data}) => {
            this.trucks = data.searchTruck as TruckPage;
        }, error => super.onError(error));
        this.setQueryString();
        this.unityQuery = this.getUnitiesGQL.watch();
        this.unityQuery.valueChanges.subscribe(({data}) => {
            this.unities = data.unities as Array<Unity>;
        });
    }

    onSearch() {
        this.searchTruckQuery.refetch({data: this.filter}).then(() => {
            this.setQueryString();
        });
    }

    actionDelete(id: string) {
        super.confirmMessage('Atenção', 'Deseja realmente excluir esse arquivo?', () => {
            this.removeTruckGQL.mutate({id}).subscribe((result) => {
                super.showMessage(this.translate.instant('success'), this.translate.instant('arquivodeletado'), 'success');
                this.onSearch();
            });
        });
    }

    // FLUXO DE iNSPECIONAR CAMINHÃO
    modalInspect(truck: Truck) {
        if (this.prohibited) {
            this.inspectTruck = new TruckInspection();
            this.inspectTruck.truck = truck;

            this.inspectTruck.date = moment().utcOffset(0, true).format();
            this.setDate('date-truck', (start, end) => {
                this.inspectTruck.date = start.utcOffset(0, true).format();
            }, this.inspectTruck.date, true, null, 'DD/MM/YYYY - HH:mm');

            this.inspectTruck.receipt = [];
            this.receipt = new TruckReceipt();
            this.showModal('inspectTruck');
        } else {

        }

    }

    pushReceiptList() {
        if (this.isNullOrUndefined(this.receipt.receipt)) {
            return this.showMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningCorrectFiscal'), 'warning');
        }
        this.inspectTruck.receipt.push(this.receipt);
        this.receipt = new TruckReceipt();
    }

    removeReceipt(index: number) {
        super.confirmMessage(this.translate.instant('atencao'), this.translate.instant('excluirnota'), () => {
            this.inspectTruck.receipt.splice(index, 1);
        });
    }

    actionInspect() {
        const arrValidateFields = [
            // {value: this.inspectTruck.sealNumber, text: `Nº do Lacre<br>`},
            // {value: this.inspectTruck.receipt, text: `Ao menos uma nota fiscal<br>`},
            // {value: this.inspectTruck.comment, text: `Comentário<br>`},
        ];

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('camposobrigatorios')} <br> ${stringError}`);
            return;
        }
        const truckInput: InspectTruckTruckInput = {
            id: this.inspectTruck.truck.id
        };
        const data: InspectTruckInput = {
            comment: this.inspectTruck.comment,
            date: this.inspectTruck.date,
            sealNumber: this.inspectTruck.sealNumber,
            truck: truckInput,
            receipt: this.inspectTruck.receipt,
            trailerLicensePlate: this.inspectTruck.trailerLicensePlate
        };

        this.inspectTruckGQL.mutate({data}).subscribe(({data}) => {
            this.searchTruckQuery.refetch({data: this.filter});
            this.uploadFileTruck(data.inspectTruck.inspection.id);
            this.closeModal('inspectTruck');
            super.showMessage(this.translate.instant('success'), this.translate.instant('inspecaorealizada'), 'success');
        }, error => super.onError(error));
    }

    // FLUXO DE LIBERAR CAMINHÃO
    modalRelease(truck: Truck) {
        if (this.exit) {
            this.releaseTruck = new TruckRelease();
            this.releaseTruck.truck = truck;
            this.sealNumberSelected = truck.inspection?.sealNumber;
            this.releaseTruck.createdAt = moment().utcOffset(0, true).format('DD/MM/YYYY - HH:mm');
            this.releaseTruck.receipt = [];
            if (!this.isNullOrUndefined(truck.inspection?.receipt)) {
                truck.inspection.receipt.forEach((e, index) => {
                    const receipt: TruckInspectionReceipt = {id: e.id, receipt: e.receipt};
                    this.releaseTruck.receipt.push({comment: '', id: '', receipt: receipt, status: null});
                });
            }
            this.showModal('releaseTruck');
        }
    }

    modalComment(index: number) {
        this.indexSelected = index;
        this.newMessage = this.releaseTruck.receipt[this.indexSelected].comment;
        this.showModal('commentTruck');
    }

    saveComment() {
        this.releaseTruck.receipt[this.indexSelected].comment = this.newMessage;
        this.closeModal('commentTruck');
        this.indexSelected = undefined;
    }

    modalSealComment() {
        this.newMessage = this.releaseTruck.sealComment;
        this.showModal('commentTruckSeal');
    }

    saveSealComment() {
        this.releaseTruck.sealComment = this.newMessage;
        this.closeModal('commentTruckSeal');
        this.indexSelected = undefined;
    }

    actionRelease() {
        const arrValidateFields = [
            // {value: this.releaseTruck.sealStatus, text: `Numero do Lacre<br>`},
            // {value: this.releaseTruck.comment, text: `Comentário<br>`},
        ];

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('camposobrigatorios')} <br> ${stringError}`);
            return;
        }

        let receiptNull = 0;
        this.releaseTruck.receipt.forEach((e) => {
            if (this.isNullOrUndefined(e.status)) {
                return receiptNull += 1;
            }
        });

        if (receiptNull > 0) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('truck.fill-invoices')} <br>`);
            return;
        }

        const receiptInputs: ReleaseTruckReceiptInput[] = [];
        this.releaseTruck.receipt.forEach((e) => {
            const receiptInput: ReleaseTruckReceiptReceiptInput = {id: e.receipt.id};
            receiptInputs.push({comment: e.comment, status: e.status, receipt: receiptInput});
        });

        const truckInput: ReleaseTruckTruckInput = {id: this.releaseTruck.truck.id};

        const d: ReleaseTruckInput = {
            comment: this.releaseTruck.comment,
            sealComment: this.isNullOrUndefined(this.releaseTruck.sealComment) ? '' : this.releaseTruck.sealComment,
            sealStatus: this.releaseTruck.sealStatus,
            truck: truckInput,
            receipt: receiptInputs,
        };
        this.releaseTruckGQL.mutate({data: d}).subscribe(({data}) => {
            this.searchTruckQuery.refetch({data: this.filter});
            this.uploadFileTruck(data.releaseTruck.release.id);
            this.closeModal('releaseTruck');
            super.showMessage(this.translate.instant('success'), this.translate.instant('liebaracaorealizada'), 'success');
        }, error => super.onError(error));
    }

    actionExcel() {
        this.excelService.getExcelTrucks(this.filter).subscribe({
            next: data => {
                this.downloadHandlerFile(data);
            }, error: err => super.onError(err)
        });
    }

    onCreate() {
        super.confirmMessage(this.translate.instant('atencao'), this.translate.instant('truck.create'), () => {
            this.createTruckGQL.mutate().subscribe(({data}) => {
                this.router.navigate([`truck/create/${data.createTruck.id}`]);

            }, error => super.onError(error));
        });
    }

    setCroppedSeal(o: any) {
        this.thumbSeal = o.thumb;
        this.fileSeal = this.handleUpload(o.file);
    }

    setCroppedLicensePlate(o: any) {
        this.thumbLicensePlate = o.thumb;
        this.fileLicensePlate = this.handleUpload(o.file);
    }

    uploadFileTruck(id) {
        if (!this.isNullOrUndefined(this.fileSeal)) {
            this.excelService.upload(this.fileSeal, `/upload/service-request/inspect/${id}/truck-seal`).subscribe({
                next: data2 => {
                }, error: err => super.onError(err)
            });
        }
        if (!this.isNullOrUndefined(this.fileLicensePlate)) {
            this.excelService.upload(this.fileLicensePlate, `/upload/service-request/release/${id}/truck-seal`).subscribe({
                next: data2 => {
                }, error: err => super.onError(err)
            });
        }
    }

    actionUserProfile() {
        switch (this.user.profile) {
            case 'admin':
                return true;
            case 'local-manager':
                return false;
            case 'user':
                return false;
            case 'vigilant-leader':
                return false;
            case 'vigilant-conductor':
                return false;
            case 'vigilant':
                return false;
            case 'lecturer':
                return false;
            case 'corporate-operator':
                return false;
            case 'local-operator':
                return false;
        }
    }

    actionUserProfileBTN() {
        switch (this.user.profile) {
            case 'admin':
                this.labelUnity = true;
                this.actionNew = true;
                this.prohibited = true;
                this.lading = true;
                this.exit = true;
                this.exportExcel = true;
                break;
            case 'local-manager':
                this.labelUnity = false;
                this.actionNew = false;
                this.prohibited = false;
                this.lading = false;
                this.exit = false;
                this.exportExcel = true;
                break;
            case 'user':
                this.labelUnity = false;
                this.actionNew = false;
                this.prohibited = false;
                this.lading = false;
                this.exit = false;
                this.exportExcel = false;
                break;
            case 'vigilant-leader':
                this.labelUnity = false;
                this.actionNew = true;
                this.prohibited = true;
                this.lading = false;
                this.exit = true;
                this.exportExcel = false;
                break;
            case 'vigilant-conductor':
                this.labelUnity = false;
                this.actionNew = true;
                this.prohibited = true;
                this.lading = false;
                this.exit = true;
                this.exportExcel = false;
                break;
            case 'vigilant':
                this.labelUnity = false;
                this.actionNew = true;
                this.prohibited = false;
                this.lading = false;
                this.exit = true;
                this.exportExcel = false;
                break;
            case 'lecturer':
                this.labelUnity = false;
                this.actionNew = false;
                this.prohibited = true;
                this.lading = true;
                this.exit = false;
                this.exportExcel = false;
                break;
            case 'corporate-operator':
                this.labelUnity = false;
                this.actionNew = false;
                this.prohibited = false;
                this.lading = false;
                this.exit = false;
                this.exportExcel = false;
                break;
            case 'local-operator':
                this.labelUnity = false;
                this.actionNew = false;
                this.prohibited = false;
                this.lading = false;
                this.exit = false;
                this.exportExcel = false;
                break;
            case 'transport':
                this.labelUnity = false;
                this.actionNew = false;
                this.prohibited = false;
                this.lading = false;
                this.exit = false;
                this.exportExcel = true;
                break;
        }
    }

    pageChanged(event: number) {
        this.filter.page = event;
        this.onSearch();
    }

    getQueryString() {
        this.activatedRoute.queryParams.subscribe((params) => {
            this.filter.start = params.start.replace('Z', '');
            this.filter.end = params.end.replace('Z', '');
        });
    }

    setQueryString() {
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {start: this.filter.start || null, end: this.filter.end || null}, // Remove o parâmetro se vazio
            queryParamsHandling: 'merge', // Mantém outros query params
        });
    }

}
