import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../../base/base.component';
import {QueryRef} from "apollo-angular";
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import moment from 'moment';
import {
    CheckListCftvBlocDto, CheckListCftvComment, CheckListCftvItem,
    CheckListCftvUnfinishedDto, CheckListCftvUpdateInput, CheckListCftvUpdateInputItem, GetCheckListCftvUnfinishedGQL,
    GetCheckListCftvUnfinishedQuery, UpdateCheckListCftvGQL
} from '../../../../generated/graphql';

@Component({
    selector: 'app-cftv-check-list',
    templateUrl: './cftv-control-create.component.html',
    styleUrls: ['./cftv-control-create.component.scss']
})
export class CftvControlCreateComponent extends BaseComponent {
    window = window;

    checklistResult: CheckListCftvUnfinishedDto = new CheckListCftvUnfinishedDto();
    checklistQuery: QueryRef<GetCheckListCftvUnfinishedQuery>;

    selectedBloc: CheckListCftvBlocDto;
    selectedItem: CheckListCftvItem = new CheckListCftvItem();
    commentModel: CheckListCftvComment = new CheckListCftvComment();
    checkListId: string;

    constructor(public router: Router,
                public translate: TranslateService,
                public getCheckListCftvUnfinishedGQL: GetCheckListCftvUnfinishedGQL,
                public updateCheckListCftvUnfinishedGQL: UpdateCheckListCftvGQL,
                public activatedRoute: ActivatedRoute) {
        super(router, translate);
        this.activatedRoute.params.subscribe(url => {
            if (!this.isNullOrUndefined(url?.id)) {
                this.checkListId = url.id;
                this.checklistQuery = this.getCheckListCftvUnfinishedGQL.watch({id: url.id});
                this.checklistQuery.valueChanges.subscribe({
                    next: ({data}) => {
                        this.checklistResult = data.checkListCftvUnfinished as CheckListCftvUnfinishedDto;
                        if (this.checklistResult.status === 'done') {
                            this.router.navigate([`cftv/detail/${this.checkListId}`]);
                        }
                    }
                });
            }
        });
    }

    actionSelectBloc(b: CheckListCftvBlocDto) {
        if (b.total == b.done) {
            return;
        }
        this.selectedBloc = new CheckListCftvBlocDto();
        const x = JSON.stringify(b);
        this.selectedBloc = JSON.parse(x) as CheckListCftvBlocDto;
    }

    actionClearSelectedBloc() {
        this.selectedBloc = null;
    }

    actionOpenCommentModal(b: CheckListCftvItem) {
        this.commentModel = new CheckListCftvComment();
        this.selectedItem = Object.assign({}, b);
        if (!this.isNullOrUndefined(this.selectedItem)) {
            if (!this.isNullOrUndefined(this.selectedItem.comments)) {
                this.commentModel = this.selectedItem.comments[0];
            }
        }
        super.showModal('commentModal');
        if (this.isNullOrUndefined(this.selectedItem.dateLimit)) {
            this.selectedItem.dateLimit = moment().add(1, "day").utcOffset(0, true).format();
        }
        this.setDate('dateLimit', (start, end) => {
            this.selectedItem.dateLimit = end.utcOffset(0, true).format();
            this.selectedItem.dateLimit = end.utcOffset(0, true).format();
        }, this.selectedItem.dateLimit, true, null, 'DD/MM/YYYY - HH:mm');
    }

    actionAddComment(event) {
        this.commentModel = event;
        if (super.isNullOrUndefined(this.commentModel.comment)) {
            this.showMessage(this.translate.instant('atencao'), this.translate.instant('Preenchaoucliquesair'));
            return;
        }

        let listItem = this.selectedBloc.items.find(it => it.id === this.selectedItem.id);

        listItem.comments = [this.commentModel];
        listItem.dateLimit = this.selectedItem.dateLimit;

        super.closeModal('commentModal');
        this.selectedItem = new CheckListCftvItem();
        this.commentModel = new CheckListCftvComment();

    }

    actionSave() {
        let model = new CheckListCftvUpdateInput();
        model.blocId = this.selectedBloc.id;
        model.checkListId = this.checkListId;

        const items: CheckListCftvUpdateInputItem[] = [];

        for (const it of this.selectedBloc.items) {

            const arrValidateFields = [
                {value: it.alimentation, text: `Alimentação câmera ${it.camera.code}<br>`},
                {value: it.alignment, text: `Alinhamento câmera ${it.camera.code}<br>`},
                {value: it.focus, text: `Foco câmera ${it.camera.code}<br>`},
                {value: it.signal, text: `Sinal câmera ${it.camera.code}<br>`},
                {value: it.daysRecorded, text: `Dias de Gravação câmera ${it.camera.code}<br>`},
            ];

            const stringError = this.validateField(arrValidateFields);
            if (!super.isNullOrUndefined(stringError)) {
                this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('camposobrigatorios')} <br> ${stringError}`);
                return;
            }

            const m = new CheckListCftvUpdateInputItem();
            m.dateLimit = it.dateLimit;
            m.alimentation = it.alimentation;
            m.alignment = it.alignment;
            m.signal = it.signal;
            m.focus = it.focus;
            m.comments = it.comments;
            m.id = it.id;
            m.daysRecorded = it.daysRecorded;

            items.push(m);
        }

        if (super.isNullOrUndefined(items)) {
            this.showMessage(this.translate.instant('atencao'), this.translate.instant('Preenchaumitemdalista'));
            return;
        }

        model.items = items;
        this.updateCheckListCftvUnfinishedGQL.mutate({data: model}).subscribe({
            next: (dt) => {
                this.actionClearSelectedBloc();
                this.checklistQuery.refetch({id: this.checkListId});
                super.showMessage(this.translate.instant('success'), this.translate.instant('Checklistenviadocomsucesso'), 'success');
            }
        });
    }

    hasItemNoOk(it: CheckListCftvItem) {
        return (!this.isNullOrUndefined(it.alimentation) && it.alimentation != 'ok')
            || (!this.isNullOrUndefined(it.alignment) && it.alignment != 'ok')
            || (!this.isNullOrUndefined(it.focus) && it.focus != 'ok')
            || (!this.isNullOrUndefined(it.signal) && it.signal != 'ok');
    }

}
