import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseComponent} from '../../../base/base.component';
import {TranslateService} from '@ngx-translate/core';
import {
    CheckListAccessComment,
    CheckListAccessItem,
    ChecklistAccessUnfinishedDto, ChecklistBlocDto, CheckListUpdateInput, CheckListUpdateInputItem,
    GetCheckListAccessUnfinishedGQL, GetCheckListAccessUnfinishedQuery, Occurrence, SearchCheckListAccessQuery,
    UpdateCheckListAccessGQL
} from '../../../../generated/graphql';
import {QueryRef} from 'apollo-angular';
import moment from 'moment';

@Component({
    selector: 'app-access-control-check-list',
    templateUrl: './access-control-create.component.html',
    styleUrls: ['./access-control-create.component.scss']
})
export class AccessControlCreateComponent extends BaseComponent {
    window = window;

    checklistResult: ChecklistAccessUnfinishedDto = new ChecklistAccessUnfinishedDto();
    checklistQuery: QueryRef<GetCheckListAccessUnfinishedQuery>;

    selectedBloc: ChecklistBlocDto;
    selectedItem: CheckListAccessItem = new CheckListAccessItem();
    commentModel: CheckListAccessComment = new CheckListAccessComment();
    checkListId: string;

    constructor(public router: Router,
                public translate: TranslateService,
                public getCheckListAccessUnfinishedGQL: GetCheckListAccessUnfinishedGQL,
                public updateCheckListAccessUnfinishedGQL: UpdateCheckListAccessGQL,
                public activatedRoute: ActivatedRoute) {
        super(router, translate);
        this.activatedRoute.params.subscribe(url => {
            if (!this.isNullOrUndefined(url?.id)) {
                this.checkListId = url.id;
                this.checklistQuery = this.getCheckListAccessUnfinishedGQL.watch({id: url.id});
                this.checklistQuery.valueChanges.subscribe({
                    next: ({data}) => {
                        this.checklistResult = data.checkListAccessUnfinished as ChecklistAccessUnfinishedDto;
                        if (this.checklistResult.status === 'done') {
                            this.router.navigate([`access-control/detail/${this.checkListId}`]);
                        }
                    }
                });
            }
        });
    }

    actionSelectBloc(b: ChecklistBlocDto) {
        if (b.total == b.done) {
            return;
        }
        this.selectedBloc = new ChecklistBlocDto();
        const x = JSON.stringify(b);
        this.selectedBloc = JSON.parse(x) as ChecklistBlocDto;
    }

    actionClearSelectedBloc() {
        this.selectedBloc = null;
    }

    actionOpenCommentModal(b: CheckListAccessItem) {
        this.commentModel = new CheckListAccessComment();
        this.selectedItem = Object.assign({}, b);
        if (!this.isNullOrUndefined(this.selectedItem)) {
            if (!this.isNullOrUndefined(this.selectedItem.comments)) {
                this.commentModel = this.selectedItem.comments[0];
            }
        }
        super.showModal('commentModal');
        if (this.isNullOrUndefined(this.selectedItem.dateLimit)) {
            this.selectedItem.dateLimit = moment().add(1, "day").utcOffset(0, true).format();
        }
        this.setDate('dateLimit', (start, end) => {
            this.selectedItem.dateLimit = end.utcOffset(0, true).format();
            this.selectedItem.dateLimit = end.utcOffset(0, true).format();
        }, this.selectedItem.dateLimit, true, null, 'DD/MM/YYYY - HH:mm');
    }

    actionAddComment(event) {
        this.commentModel = event;
        if (super.isNullOrUndefined(this.commentModel.comment)) {
            this.showMessage(this.translate.instant('atencao'), this.translate.instant('Preenchaoucliquesair'));
            return;
        }

        let listItem = this.selectedBloc.items.find(it => it.id === this.selectedItem.id);

        listItem.comments = [this.commentModel];
        listItem.dateLimit = this.selectedItem.dateLimit;

        super.closeModal('commentModal');
        this.selectedItem = new CheckListAccessItem();
        this.commentModel = new CheckListAccessComment();

    }

    actionSave() {
        let model = new CheckListUpdateInput();
        model.blocId = this.selectedBloc.id;
        model.checkListId = this.checkListId;

        const items: CheckListUpdateInputItem[] = [];

        for (const it of this.selectedBloc.items.filter(x => !this.isNullOrUndefined(x.condition))) {
            const m = new CheckListUpdateInputItem();
            m.dateLimit = it.dateLimit;
            m.condition = it.condition;
            m.comments = it.comments;
            m.id = it.id;

            items.push(m);
        }

        if (super.isNullOrUndefined(items)) {
            this.showMessage(this.translate.instant('atencao'), this.translate.instant('Preenchaumitemdalista'));
            return;
        }

        model.items = items;
        this.updateCheckListAccessUnfinishedGQL.mutate({data: model}).subscribe({
            next: (dt) => {
                this.actionClearSelectedBloc();
                this.checklistQuery.refetch({id: this.checkListId});
                super.showMessage(this.translate.instant('success'), this.translate.instant('Checklistenviadocomsucesso'), 'success');
            }
        });
    }

}
