import {AfterViewInit, Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../../base/base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {
    ServiceRequest,
    ServiceRequestGQL,
    ServiceRequestInspect,
    ServiceRequestQuery, User
} from "../../../../generated/graphql";
import {TranslateService} from "@ngx-translate/core";
import {QueryRef} from "apollo-angular";
import {environment} from "../../../../environments/environment";
import {UserService} from "../../../service/user.service";

@Component({
    selector: 'app-services-detail',
    templateUrl: './services-detail.component.html',
    styleUrls: ['./services-detail.component.scss']
})
export class ServicesDetailComponent extends BaseComponent implements OnInit, AfterViewInit {
    window = window;
    user = new User();
    paramId = '';
    model: ServiceRequest = new ServiceRequest();
    modelQuery: QueryRef<ServiceRequestQuery>;

    constructor(public router: Router,
                private serviceRequestGQL: ServiceRequestGQL,
                public activatedRoute: ActivatedRoute,
                public userService: UserService,
                public translate: TranslateService) {
        super(router, translate);
        this.model.helper = [];
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
            }, error: err => super.onError(err)
        });
        this.activatedRoute.params.subscribe(u => {
            if (!this.isNullOrUndefined(u.id)) {
                this.paramId = u.id;
                this.modelQuery = this.serviceRequestGQL.watch({data: this.paramId});
                this.modelQuery.valueChanges.subscribe(({data}) => {
                    this.model = data.serviceRequest as ServiceRequest;
                });
            }
        });
    }

    actionPdf() {
        const token = localStorage.getItem('token') as string;
        const id = this.model.id as string;
        const url = `${environment.base_url}/service-request/pdf?id=${id}&token=${token}`;
        window.open(encodeURI(url));
    }

    actionUserProfile() {
        switch (this.user.profile) {
            case 'admin':
                return true;
            case 'local-manager':
                return true;
            case 'user':
                return false;
            case 'vigilant-leader':
                return true;
            case 'vigilant-conductor':
                return false;
            case 'vigilant':
                return false;
            case 'lecturer':
                return false;
            case 'corporate-operator':
                return false;
            case 'local-operator':
                return false;
        }
    }

}
